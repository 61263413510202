import React, { useState } from 'react';
import AdminService from '../../../../services/admin.service';

const AgentList = ({ index, agent, agentLists, department,addAgentToDepartment }) => {
  // const [addAgent, setAddagent] = useState();

  
  return (
    <li key={index} className='flex justify-between'>
      <div className='flex flex-col justify-start items-start mt-2'>
        <div className='flex gap-2'>
          <span className='flex text-sm p-2 mt-2 items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-300 text-white'>
            {agent.displayName
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </span>
          <div className='flex flex-col justify-start items-start'>
            <label className='text-gray-700 text-lg'>{agent.displayName} </label>
            <span className='text-gray-400 text-sm'>{agent.userPrincipalName} </span>
          </div>
        </div>
      </div>
      <div className='mt-6 '>
        {console.log(JSON.stringify(agentLists), 'agentLists=ll')}
        {console.log(JSON.stringify(agent), 'agent')}
        {agentLists.find(
          (agents) => agents.department === agent.department && agents.mail === agent.mail
        ) ? (
          <button>
            <svg
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 11.25L9.25 13.5L13 8.25M19 10.5C19 11.6819 18.7672 12.8522 18.3149 13.9442C17.8626 15.0361 17.1997 16.0282 16.364 16.864C15.5282 17.6997 14.5361 18.3626 13.4442 18.8149C12.3522 19.2672 11.1819 19.5 10 19.5C8.8181 19.5 7.64778 19.2672 6.55585 18.8149C5.46392 18.3626 4.47177 17.6997 3.63604 16.864C2.80031 16.0282 2.13738 15.0361 1.68508 13.9442C1.23279 12.8522 1 11.6819 1 10.5C1 8.11305 1.94821 5.82387 3.63604 4.13604C5.32387 2.44821 7.61305 1.5 10 1.5C12.3869 1.5 14.6761 2.44821 16.364 4.13604C18.0518 5.82387 19 8.11305 19 10.5Z'
                stroke='#3F8CFF'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        ) : (
          <button onClick={() => addAgentToDepartment(agent)}>
            <svg
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 7.5V13.5M13 10.5H7M19 10.5C19 11.6819 18.7672 12.8522 18.3149 13.9442C17.8626 15.0361 17.1997 16.0282 16.364 16.864C15.5282 17.6997 14.5361 18.3626 13.4442 18.8149C12.3522 19.2672 11.1819 19.5 10 19.5C8.8181 19.5 7.64778 19.2672 6.55585 18.8149C5.46392 18.3626 4.47177 17.6997 3.63604 16.864C2.80031 16.0282 2.13738 15.0361 1.68508 13.9442C1.23279 12.8522 1 11.6819 1 10.5C1 8.11305 1.94821 5.82387 3.63604 4.13604C5.32387 2.44821 7.61305 1.5 10 1.5C12.3869 1.5 14.6761 2.44821 16.364 4.13604C18.0518 5.82387 19 8.11305 19 10.5Z'
                stroke='#8F8F8F'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        )}
      </div>
    </li>
  );
};

export default AgentList;
