import { useParams } from "react-router-dom";
import searchIcon from "../../../../assets/images/searchicon.svg";
import { useCallback, useEffect, useState } from "react";
import AgentList from "./agentList";
import axios from "axios";
import { useLocation } from "react-router-dom";
import AdminService from "../../../../services/admin.service";

const DepartmentDetail = () => {
  const { department } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [agentsearch, setAgentsearch] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentLists, setAgentList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const handleSearchAgent = () => {
    setAgentsearch((prev) => !prev);
  };

  const handleDelete = async (e, id, displayName,department,uuid) => {
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      `Are you sure you want to delete ${displayName}?`
    );

    if (confirmed) {
      try {
        const response = await AdminService.deleteAgent(id,department,uuid);
        alert(response.message);
        setAgentList((prevList) => prevList.filter((agent) => agent.id !== id));
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    } else {
      // Cancel deletion
      alert("Deletion canceled");
    }
  };

  const getAgent = useCallback(() => {
    AdminService.getAgents()
      .then((agentList) => {
        setAgentList(agentList?.data?.result);
        
      })
      .catch(() => setAgentList([]));
  }, []); // Empty dependency array means this function is created only once



  useEffect(() => {
    getAgent(); // Call the memoized function
  }, [agentLists, getAgent]);
  // agentLists

  const handleSearch = async (value) => {
    if (value.length < 3) {
      setAgents([]); // Clear agents if less than 3 characters
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CCS_URL}/ms-users`,
        {
          params: { search: value },
        }
      );
      setAgents(response.data); // Assuming the response is an array of agents
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };
  const addAgentToDepartment = async (agent) => {
    agent.department = department;
    const payload = agent;
    const newAgent = await AdminService.createAgent(payload);
    // let msg = newAgent?.stack?.split(`\n`)[0];
    if (newAgent?.statusCode === 200) {
      setRefresh((prev) => !prev);
    } else {
      console.log(newAgent);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchTerm);
    }, 300); // Adjust the debounce delay as necessary

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  return (
    <>
      <div className=" w-full h-[100vh] p-8">
        <p className="font-medium text-2xl">{department}</p>
        <div className="flex justify-between space-x1">
          <div className="pt-5 w-80 items-start">
            <div className="search-bar bg-white border border-gray-300 flex items-center gap-4 px-3 py-1 w-full max-w-sm rounded-lg shadow-sm">
              <div className="search-icon flex items-center justify-center w-5 h-[40px] rounded-full">
                <img src={searchIcon} alt="search icon" />
              </div>
              <input
                type="text"
                placeholder="Department"
                className="font-medium text-gray-700 w-full focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start mt-[40px]">
          <label className="text-base text-blue-900">Members</label>
          <div className="flex flex-wrap mt-[20px] gap-4">
            <button
              onClick={handleSearchAgent}
              className="relative w-[134px] h-[50px] text-blue-500 bg-blue-50 rounded-lg border border-blue-200 text-base font-normal leading-[22.4px] text-center"
            >
              <span>+</span>Add Agent
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={`${
                  !agentsearch ? "hidden" : "flex"
                } absolute  flex-col w-[600px] h-[385px] border border-gray-200 rounded-lg bg-white p-4 mt-6 z-10`}
              >
                <label className="text-gray-600 text-left">Add Agent</label>
                <div className="pt-2 w-full items-start bg-white">
                  <div className="search-bar border border-gray-300 flex items-center gap-4 px-3 py-1 w-full rounded-lg shadow-sm">
                    <div className="flex items-center justify-center h-[40px] rounded-full round-lg">
                      <img
                        src={searchIcon}
                        alt="search icon"
                        className="!bg-white"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Search agent by email or username"
                      className="font-medium text-gray-700 w-full focus:outline-none"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <ul>
                  {agents?.slice(0, 5).map((agent, index) => {
                    return (
                      <AgentList
                        index={index}
                        agent={agent}
                        agentLists={agentLists}
                        department={department}
                        addAgentToDepartment={addAgentToDepartment}
                      />
                    );
                  })}
                </ul>
              </div>
            </button>
            {agentLists
              ?.filter((agent) => agent.department === department)
              .map((agent, index) => {
                return (
                  <button className="flex gap-x-2 relative p-2 h-[50px] whitespace-nowrap text-gray-600 rounded-lg border border-blue-50 text-base font-normal leading-normal text-center group">
                    <div className="flex text-sm  items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-300 text-white">
                      <p className=" pt-1 m-0">
                        {agent.displayName
                          .split(" ")
                          .map((n) => n[0])
                          .join("")}
                      </p>
                    </div>
                    <span className="mt-2">{agent.displayName}</span>
                    <span className="absolute right-0 top-0 w-1/2 h-full bg-red-100 rounded-r-lg  flex items-center justify-center opacity-0 group-hover:opacity-50 transition-opacity duration-300">
                      <button
                        onClick={(e) =>
                          handleDelete(e, agent.id, agent.displayName,agent.department,agent.uuid)
                        }
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 13L13 1M1 1L13 13"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </span>
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentDetail;
